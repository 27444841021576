"use client";

import { useRouter } from "next/navigation";
import { type ReactNode, useCallback, useEffect, useState } from "react";

import { useUserContext } from "@/context/user.context";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import type { DataDumpResponse } from "@/types/api";
import {
  type ExtensionMessageEvents,
  ExtensionOutgoingMessageTypes,
} from "@/types/extension";
import { sendPageData } from "@/utils/extension";

export const ExtensionProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const track = useTrackAnalytics();
  const { company } = useUserContext();
  const [pageData, setPageData] = useState<DataDumpResponse>(); // response from extension

  const handleMessageEvent = useCallback(
    ({ data }: ExtensionMessageEvents) => {
      switch (data.type) {
        case ExtensionOutgoingMessageTypes.PageDataCollected: {
          if (company) {
            sendPageData({ companyId: company.id, dom: data.dom }).then(
              (data: DataDumpResponse) => {
                setPageData(data);
              },
            );
          }

          break;
        }
        case ExtensionOutgoingMessageTypes.TrackAnalytics: {
          const { eventName, properties, options } = data.payload;

          track(eventName, properties, options);

          break;
        }
      }
    },
    [company, track],
  );

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);

    return () => window.removeEventListener("message", handleMessageEvent);
  }, [handleMessageEvent]);

  useEffect(() => {
    if (pageData) {
      switch (pageData.page) {
        case "all":
          router.push("/");
          break;
        case "candidate": {
          const candidateEmails = pageData.candidateEmails;
          router.push(`/candidates/${encodeURIComponent(candidateEmails[0])}`);
          break;
        }
        case "job": {
          const screenerLocationId = pageData.screenerLocationIds[0];
          router.push(`/screener-location/${screenerLocationId}`);
          break;
        }
      }
    }
  }, [pageData, router]);

  return children;
};
