"use client";

import { createTheme, MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { type PropsWithChildren } from "react";

import { ExtensionProvider } from "@/components/extension-provider";
import { SegmentContextProvider } from "@/context/segment.context";
import { UserContextProvider } from "@/context/user.context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // 15 minutes
      staleTime: 15 * 60 * 1000,
    },
  },
});

const theme = createTheme({
  fontSizes: {
    xs: "0.766rem",
  },
});

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <UserContextProvider>
          <SegmentContextProvider>
            <ExtensionProvider>{children}</ExtensionProvider>
          </SegmentContextProvider>
        </UserContextProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};
